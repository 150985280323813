import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/reviewPage.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ReviewHeader = makeShortcode("ReviewHeader");
const ReviewFooter = makeShortcode("ReviewFooter");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ReviewHeader data={props.pageContext.frontmatter} mdxType="ReviewHeader" />
    <h1>{`Overall`}</h1>
    <p>{`Better than some, but not super stand out.
We wouldn’t drive across the city for it, but it'll do the job in a pinch.`}</p>
    <h1>{`Key Points:`}</h1>
    <ul>
      <li parentName="ul">{`Compostable containers, and they asked if we needed utensils. 😍 #inlove`}</li>
      <li parentName="ul">{`Asking for extra ginger doesn't get you very far here.`}</li>
      <li parentName="ul">{`Rice is pretty bland but well cooked.`}</li>
      <li parentName="ul">{`Lots of inventive sauce options with great descriptions.`}</li>
      <li parentName="ul">{`Helpful service.`}</li>
    </ul>
    <h1>{`Biggest Suggestion`}</h1>
    <p>{`Invest in higher quality fish. 🐟`}</p>
    <ReviewFooter data={props.pageContext.frontmatter} mdxType="ReviewFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      